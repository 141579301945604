<template>
  <div class="c-container ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-4 md:c-col-8 lg:c-col-6">
        <div class="ui-relative c-bg-white ui-rounded-md">
          <!-- Content -->
          <div class="c-row ui-h-full ui-p-8 ui-pb-0">
            <!-- Coluna esquerda -->
            <div class="c-col-full sm:c-col-1/2 ui-pb-8 sm:ui-mt-0">
              <div class="ui-flex ui-flex-col ui-h-full">
                <!-- Título e subtitulo -->
                <div class="ui-flex-1">
                  <!-- Titulo -->
                  <div class="c-text-subtitle">
                    <span>{{ title }}</span>
                  </div>

                  <!-- Subtitulo -->
                  <div class="c-text-b2 ui-mt-3">
                    <span>{{ subTitle }}</span>
                  </div>
                  <p-input
                    v-model="user.mfaCode"
                    mask="000000"
                    :maxlength="6"
                    type="text"
                    label="Código de verificação"
                    input-class="mfa-code-input"
                    class="ui-mb-2"
                  />
                  <div class="q-pt-xs">
                    <c-button
                      full
                      data-cy="registerButton"
                      size="sm"
                      type="submit"
                      color="primary"
                      :disabled="mfaRetryCounter > 0"
                      @click="() => requestAnotherMFA()"
                    >
                      {{ MFAButtonLabel }}
                      <icon
                        name="next"
                        class="c-icon--right"
                      />
                    </c-button>
                  </div>
                </div>

                <!-- Botão -->
                <div class="ui-pt-4 md:ui-inline-flex">
                  <c-button
                    full
                    size="lg"
                    @click="confirmButton.onClick(user.mfaCode)"
                  >
                    {{ confirmButton.label }}
                  </c-button>

                  <c-button
                    v-if="cancelButton.label !== ''"
                    outline
                    full
                    size="lg"
                    class="ui-mt-4 md:ui-mt-0 md:ui-ml-4"
                    @click="cancelButton.onClick"
                  >
                    {{ cancelButton.label }}
                  </c-button>
                </div>
              </div>
            </div>

            <!-- Coluna direita -->
            <div class="c-col-full sm:c-col-1/2">
              <!-- Ilustração -->
              <div class="c-d-flex ui-h-full ui-justify-center">
                <img
                  :src="illustrationPath"
                  class="ui-mt-auto"
                  alt="Ilustração"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import PInput from '@/components/p-input';

const types = {
  success: {
    illustrationPath: require('@/assets/images/p-modal/success.svg'),
  },
  warning: {
    illustrationPath: require('@/assets/images/p-modal/warning.svg'),
  },
  error: {
    illustrationPath: require('@/assets/images/p-modal/warning.svg'),
  },
};

export default {
  name: 'MFAModal',
  components: {
    CButton,
    PInput,
    Icon,
  },
  data: () => {
    return {
      user: {
        mfaCode: '',
      },
      MFAButtonLabel: 'Enviar outro código em 30 segundos',
      mfaRetryCounter: 30,

    };
  },
  methods: {
    updateMFAButtonLabel () {
      if (this.mfaRetryCounter > 0) {
        this.MFAButtonLabel = `Enviar outro código em ${this.mfaRetryCounter} segundos`;
      } else {
        this.MFAButtonLabel = 'Enviar outro código';
      }
    },
    async requestAnotherMFA() {
      try {
        this.mfaRetryCounter = 30;
        await this.$services.accounts.requestMFAToDeleteAccount();
      } catch (e) {
        console.error(e);
      }
    },
  },
  watch: {
    mfaRetryCounter: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.mfaRetryCounter--;
            this.updateMFAButtonLabel();
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  computed: {
    illustrationPath() {
      return types[this.type].illustrationPath;
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (type) => !!types[type],
    },
    confirmButton: {
      type: Object,
      default: () => ({
        onClick: () => this.$modal.hide(),
        label: 'Fechar',
      }),
    },
    cancelButton: {
      type: Object,
      default: () => ({
        onClick: () => this.$modal.hide(),
        label: '',
      }),
    },
    justification: {
      type: String,
      default: '',
    },
  },
};
</script>
<style>
.mfa-code-input {
  font-family: ui-monospace, monospace;
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
}
</style>
